import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { graphql } from 'gatsby';

import Seo from '../components/Seo';
import useTapVerb from '../components/utils/useTapVerb';
import Layout from '../components/Layout';
import HouseSlice from '../components/HouseSlice';

import styles from './index.module.scss';

function Page({ location, data }) {
    const { allHousesJson } = data;
    const houses = get(allHousesJson, 'edges', []);
    const tapVerb = useTapVerb();
    const sections = houses.map((post, i) => {
        const house = post.node;

        return (
            <li
                key={i}
                className={styles.listItem}
            >
                <HouseSlice house={house} />
            </li>
        );
    });
    const layoutStyle = {
        maxWidth: null,
        padding: 0
    };

    return (
        <Layout location={location} style={layoutStyle}>
            <Seo title="Home" />

            <h1 className={styles.title}>A list of houses &amp;<br />shit I like about them</h1>
            <small className={styles.tapMessage} style={{ opacity: tapVerb ? 1 : 0 }}>{`(${tapVerb || 'Tap'} tiles to see pics)`}</small>
            <ul className={styles.listContainer}>
                {sections}
            </ul>
        </Layout>
    );
}

Page.propTypes = {
    location: PropTypes.object.isRequired,
    data: PropTypes.object
};

export default Page;

export const pageQuery = graphql`query HomeHousesQuery {
    allHousesJson(
        sort: { fields: listed, order: DESC }
    ) {
        edges {
            previous {
                id
                idType
                source
                listed
                address
                beds
                price
                pros
                cons
                baths
                sqft
                ...House_details
            }
            next {
                id
                idType
                source
                listed
                address
                beds
                price
                pros
                cons
                baths
                sqft
                ...House_details
            }
            node {
                id
                idType
                source
                listed
                address
                beds
                price
                pros
                cons
                baths
                sqft
                ...House_details
            }
        }
    }
}`;
