const BaseStrategy = require('./base');
const assert = require('assert');
const moment = require('moment');
const debug = require('debug');


module.exports = class ZillowStrategy extends BaseStrategy {
    static testUrl(url) {
        const log = debug('saveProperty:ZillowStrategy:testUrl');
        const matched = !!url.match(/^https?:\/\/(?:www\.)?zillow.com/);

        if (matched) {
            log(`Matched ${url}`);
        }

        return matched;
    }

    async isReady() {
        const log = debug(`saveProperty:${this.constructor.name}:isReady`);

        log('starting…');
        await Promise.all([
            this.page.waitForSelector('.ds-container'),
            this.page.waitForSelector('.zsg-icon-expando-right')
        ]);

        log('ready');
    }

    async getInfo() {
        const log = debug(`saveProperty:${this.constructor.name}:getInfo`);
        const page = this.page;
        const info = {
            source: this.url
        };

        const addressSel = '.ds-address-container';
        await page.waitForSelector(addressSel);
        const address = await page.$eval(addressSel, (el) => el.textContent);
        log(`Scraped address: ${address}`);
        assert(address, 'No address found in markup');
        info.address = address;

        const priceSel = '.ds-price';
        await page.waitForSelector(priceSel);
        const price = await page.$eval(priceSel, (el) => parseInt(el.textContent.replace(/\D+/g, ''), 10));
        log(`Scraped price: ${price}`);
        assert(price, 'No price found in markup');
        info.price = price;

        const bedBathSqftContainer = '.ds-bed-bath-living-area-header';
        const bedBathSqft = '.ds-bed-bath-living-area-container > .ds-bed-bath-living-area';
        await page.waitForSelector(bedBathSqftContainer);
        const [bed, bath, sqft] = await page.$$eval(bedBathSqft, (els) => {
            return [
                parseInt(els[0].textContent, 10),
                parseFloat(els[1].textContent.match(/\d+(?:\.)?(?:\d+)?/gim)[0]),
                parseInt(els[2].textContent.replace(/\D+/g, ''), 10)
            ];
        });
        log(`Scraped beds: ${bed}`);
        assert(bed, 'No bed found in markup');
        log(`Scraped baths: ${bath}`);
        assert(bath, 'No bath found in markup');
        log(`Scraped sqft: ${sqft}`);

        if (!sqft) {
            log('Defaulting sqft to 0');
        }

        info.beds = bed;
        info.baths = bath;
        info.sqft = sqft || 0;

        const listDateSel = '.ds-overview-stats .ds-overview-stat .ds-overview-stat-value';
        await page.waitForSelector(listDateSel);
        const listDateText = await page.$eval(listDateSel, (el) => el.textContent);
        log(`Scraped listDateText: ${listDateText}`);
        assert(listDateText, 'No list date found in markup');
        const listDateParts = listDateText.split(' ');

        info.listed = moment().subtract(listDateParts[0], listDateParts[1]).unix();

        log('Looking for MLS ID');
        const panelSel = '.ds-data-col';
        await page.waitForFunction((selector) => {
            return !!document.querySelector(selector).innerHTML.match(/MLS ID:\s+?[a-z0-9]+/i);
        }, {}, panelSel);

        const mlsIdMatch = await page.$eval(panelSel, (el) => el.innerHTML.match(/MLS ID:\s+?[a-z0-9]+/i)[0]);
        log(`Got mlsText: ${mlsIdMatch}`);
        assert(mlsIdMatch, 'No MLS ID found in markup');
        info.id = mlsIdMatch.split(':')[1].trim();
        info.idType = BaseStrategy.ID_TYPE_MLS;

        log(`Final info: ${JSON.stringify(info, null, 4)}`);

        return info;
    }

    async getImages() {
        const log = debug(`saveProperty:${this.constructor.name}:getImages`);
        const page = this.page;

        log('Waiting for images appear');
        await page.waitForSelector('.ds-media-col');

        log('Scrolling down to load in lazy loaded images');
        await page.evaluate(`(() => {
            const scrollContainer = document.querySelector('.ds-media-col');
            scrollContainer.scrollBy(0,  Number.MAX_SAFE_INTEGER);
        })()`);

        // Images are lazy loaded
        const imageContainerCount = await page.$$eval('.media-stream .media-stream-tile', (el) => el.length);

        // So after we've scrolled down, check to make sure
        // all containers have an image
        log('Checking that all image containers have image children');
        await page.waitForFunction((selector, expectedCount) => {
            // Subtract one because sometimes theres an upsell
            // tile that doesnt have an image
            return document.querySelectorAll(selector).length >= expectedCount - 1;
        }, {}, '.media-stream .media-stream-tile picture', imageContainerCount);
        log('Confirmed image count');

        const images = await page.evaluate(() => {
            const imageEls = Array.from(document.querySelectorAll('.media-stream .media-stream-tile picture img'));
            return imageEls.map((img) => {
                let src = img.getAttribute('src');

                // Get's the larger version of the thumb image
                src = src.replace(/cc_.+?[^\/.]+/, 'uncropped_scaled_within_1344_1008');

                return src;
            });
        });

        log(`Found ${images.length} images in ${imageContainerCount} image containers.`);

        return images;
    }
};
