const assert = require('assert');

class BaseStrategy {
    static testUrl() {
        throw new Error('`testUrl(url: String)` not implemented in strategy');
    }

    constructor(url, page) {
        assert(url, 'Must pass url to constructor');
        assert(page, 'Must pass browser page to constructor');
        this.url = url;
        this.page = page;
    }

    get strategyName() {
        return this.constructor.name;
    }

    async isReady() {
        throw new Error('`isReady()` not implemented in strategy');
    }

    async getImages() {
        throw new Error('`getImages()` not implemented in strategy');
    }

    async getInfo() {
        throw new Error('`getInfo()` not implemented in strategy');
    }
}

BaseStrategy.ID_TYPE_MLS = 'MLS';

module.exports = BaseStrategy;
