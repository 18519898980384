import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Link, graphql } from 'gatsby';
import moment from 'moment';

import { getHouseLink } from '../utils/index';

import styles from './HouseSlice.module.scss';
import ZillowIcon from '../images/icons/zillow.inline.svg';
import ZillowStrategy from '../../scripts/saveProperty/strategies/zillow';
import RealtorIcon from '../images/icons/realtor.inline.svg';
import RealtorStrategy from '../../scripts/saveProperty/strategies/realtor';
import HouseStats from './HouseStats';
import HouseLikes from './HouseLikes';

function HouseSlice({ house }) {
    // const { mobileImage, desktopImage } = this.props;

    function renderTileImage() {
        const { small, medium } = house.fluidImages[0].childImageSharp;

        const sources = [
            small,
            {
                ...medium,
                media: '(min-width: 768px)'
            }
        ];

        return <Img fluid={sources} className={styles.image} />;
    }

    function renderSourceIcon() {
        if (!house.source) {
            return null;
        }

        let site;
        let icon;

        if (ZillowStrategy.testUrl(house.source)) {
            icon = <ZillowIcon />;
            site = 'Zillow';
        }
        else if (RealtorStrategy.testUrl(house.source)) {
            icon = <RealtorIcon />;
            site = 'Realtor';
        }

        if (!icon) {
            return null;
        }

        const tooltip = `View on ${site}`;

        return (
            <a
                href={house.source}
                rel="nofollow noopener"
                target="_blank"
                aria-label={tooltip}
                data-tooltip={tooltip}
                data-tooltip-align-right
                className={styles.sourceIcon}
            >
                {icon}
            </a>
        );
    }

    function renderListingDate() {
        if (!house.listed) {
            return null;
        }

        return (
            <small className={styles.listingDate}>{moment(house.listed * 1000).fromNow()}</small>
        );
    }

    return (
        <div className={styles.container}>
            <Link to={getHouseLink(house)} className={styles.mainContent}>
                {renderTileImage()}
                <div className={styles.content}>
                    <h3 className={styles.address}>{house.address}</h3>

                    <HouseStats house={house} tag="small" />
                    <HouseLikes house={house} tag="small" className={styles.likes} />
                </div>
            </Link>
            {renderListingDate()}
            {renderSourceIcon()}
        </div>
    );
}

HouseSlice.propTypes = {
    house: PropTypes.object.isRequired
};

export default HouseSlice;

export const fragment = graphql`
fragment House_details on HousesJson {
    id
    fluidImages: images {
        childImageSharp {
            small: fluid(maxWidth: 500, maxHeight: 500) {
                src
                srcSet
                aspectRatio
                sizes
            }
        }
        childImageSharp {
            medium: fluid(maxWidth: 1000, maxHeight: 1000) {
                src
                srcSet
                aspectRatio
                sizes
            }
        }
    }
}`;
