const BaseStrategy = require('./base');
const assert = require('assert');
const moment = require('moment');
const debug = require('debug');

module.exports = class RealtorStrategy extends BaseStrategy {
    static testUrl(url) {
        const log = debug('saveProperty:RealtorStrategy:testUrl');
        const matched = !!url.match(/^https?:\/\/(?:www\.)?realtor.com/);

        if (matched) {
            log(`Matched ${url}`);
        }

        return matched;
    }

    async isReady() {
        const log = debug(`saveProperty:${this.constructor.name}:isReady`);

        log('starting…');
        await Promise.all([
            this.page.waitForSelector('.owl-stage-outer'),
            this.page.waitForSelector('.header-main-info')
        ]);

        log('ready');
    }

    async getInfo() {
        const log = debug(`saveProperty:${this.constructor.name}:getInfo`);
        const page = this.page;
        const info = {
            source: this.url
        };

        const addressSel = '.ldp-header-address';
        await page.waitForSelector(addressSel);
        const address = await page.$eval(addressSel, (el) => el.getAttribute('content'));
        log(`Scraped address: ${address}`);
        assert(address, 'No address found in markup');
        info.address = address;

        const priceSel = '.ldp-header-price';
        await page.waitForSelector(priceSel);
        const ret = await page.$eval(priceSel, (el) => {
            return [
                el.textContent.toLowerCase().includes('sold') || el.textContent.toLowerCase().includes('est.'),
                parseInt(el.textContent.replace(/\D+/gim, ''), 10)
            ];
        });
        const wasSold = ret[0];
        let price = ret[1];

        if (ret.wasSold) {
            log('House looks sold. Looking for last price table…');
            try {
                price = await page.$$eval('#ldp-price-tax-history .load-more-history tbody tr td', (els) => {
                    return parseInt(els[2].textContent.replace(/\D+/gim, ''), 10);
                });
            }
            catch (err) {
                // dont care
            }
        }

        log(`Scraped price: ${price}`);
        assert(typeof price === 'number', 'No price found in markup');
        info.price = price;

        const bedBathSqftContainer = '#ldp-property-meta';
        await page.waitForSelector(bedBathSqftContainer);
        const [bed, bath, sqft] = await page.$eval(bedBathSqftContainer, (el) => {
            const lis = el.querySelectorAll('li');
            return [
                parseInt(lis[0].textContent.replace(/\D+/gim, ''), 10),
                parseFloat(lis[1].textContent.match(/\d+(?:\.)?(?:\d+)?/gim)[0]),
                parseInt(lis[2].textContent.replace(/\D+/gim, ''), 10)
            ];
        });
        log(`Scraped beds: ${bed}`);
        assert(bed, 'No bed found in markup');
        log(`Scraped baths: ${bath}`);
        assert(bath, 'No bath found in markup');
        log(`Scraped sqft: ${sqft}`);

        if (!sqft) {
            log('Defaulting sqft to 0');
        }

        info.beds = bed;
        info.baths = bath;
        info.sqft = sqft || 0;

        let listDate = 0;

        try {
            const [month, day, year] = await page.$$eval('#ldp-history-price .load-more-history tbody tr', (rows) => {
                const listedRow = rows.find((row) => {
                    return row.textContent.match(/listed/i);
                });
                return listedRow.children[0].textContent.split('/');
            });
            listDate = moment(`${year}-${month}-${day}`).unix();
            log(`Scraped listDateText: ${listDate}`);
            assert(listDate, 'No list date found in markup');
        }
        catch (err) {
            if (wasSold) {
                log('Cannot find listing date. Probably because it was sold or taken off market. Defaulting to 0');
            }
        }

        info.listed = listDate;

        if (wasSold) {
            console.log('Realtor.com does not display MLS information on sold houses. Fill this out from elsewhere. Using placeholder values…');
            info.idType = 'Unknown';
            info.id = `${Date.now()}`;
        }
        else {
            log('Looking for MLS ID');

            const listingSel = '#ldp-listing-provider-info';
            await page.waitForSelector(listingSel);
            const [dataSource, sourceId] = await page.$eval(listingSel, (el) => {
                const rows = el.querySelectorAll('tr');
                const source = rows[1].querySelectorAll('td')[1];
                const id = rows[2].querySelectorAll('td')[1];

                return [
                    source.textContent.trim(),
                    id.textContent.trim()
                ];
            });

            log(`Got mlsText: ${dataSource}`);
            assert(dataSource.match(/mls/i), 'No MLS ID found in markup');
            info.id = sourceId;
            info.idType = BaseStrategy.ID_TYPE_MLS;
        }

        log(`Final info: ${JSON.stringify(info, null, 4)}`);

        return info;
    }

    async getImages() {
        const log = debug(`saveProperty:${this.constructor.name}:getImages`);
        const page = this.page;

        log('Looking for images');
        await page.waitForSelector('.owl-stage');

        const images = await page.evaluate(() => {
            const stage = document.querySelector('.owl-stage');
            const containers = stage.querySelectorAll('.owl-item');
            const imageContainers = Array.from(containers).filter((container) => {
                return !container.className.includes('cloned');
            });

            return imageContainers.map((container) => {
                const image = Array.from(container.querySelectorAll('img')).pop();
                let src = image.getAttribute('data-src') || image.getAttribute('src');

                // Replace sizing attributes so we get the
                // highest quality image

                src = src.replace(/\-w\d+_/, '-w3000_');
                src = src.replace(/_h\d+_/, '_h3000_');

                return src;
            });
        });

        log(`Found ${images.length} images.`);

        return images;
    }
};
